<template>
  <div>
    <h1>数学算式生成器</h1>
    <div class="input-section">
      <div>
        <label for="operandMin">最小数字范围：</label>
        <input type="number" id="operandMin" v-model="operandRangeMin" />
      </div>
      <div>
        <label for="operandMax">最大数字范围：</label>
        <input type="number" id="operandMax" v-model="operandRangeMax" />
      </div>
      <div>
        <label>可用的运算符：</label>

        <label v-for="(cls,index) in l_list" :key="index">
          <input type='checkbox' :checked="enabledOperators.indexOf(cls)>=0" @click='checkedOne(cls)'>{{cls}}
        </label>

      </div>
    </div>
    <div class="button-section">
      <button @click="generateExpressions">生成算式</button>
      <button @click="printExpressions">打印</button>
    </div>
    <div class="expression-container">
      <div v-for="(expression, index) in mathExpressions" :key="index" class="expression">
        <p>{{ expression }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data () {
    return {
      l_list: ['+', '-', '*', '/'],
      mathExpressions: [],
      operandRangeMin: 1,
      operandRangeMax: 10,
      enabledOperators: ['+']
    };
  },
  methods: {
    checkedOne (id) {
      let idIndex = this.enabledOperators.indexOf(id)
      if (idIndex >= 0) {
        // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
        this.enabledOperators.splice(idIndex, 1)
      } else {
        // 选中该checkbox
        this.enabledOperators.push(id)
      }

    },
    isOperatorEnabled (operator) {
      return this.enabledOperators.includes(operator);
    },
    toggleOperator (operator) {
      if (this.isOperatorEnabled(operator)) {
        this.enabledOperators = this.enabledOperators.filter(op => op !== operator);
      } else {
        this.enabledOperators.push(operator);
      }
    },
    getRandomNumber () {
      return Math.floor(Math.random() * (this.operandRangeMax - this.operandRangeMin + 1) + this.operandRangeMin);
    },
    countDecimalPlaces (num) {
      const decimalPart = String(num).split('.')[1]; // 将数字转换为字符串，并使用小数点拆分字符串获取小数部分
      if (decimalPart) {
        return decimalPart.length;
      } else {
        return 0; // 如果没有小数部分，则返回 0
      }
    },
    generateExpression () {
      let result = 3.33333333;
      let num1, num2, num3 = 0;
      let operator, operator1 = "";
      while (this.countDecimalPlaces(result) > 1) {
        num1 = this.getRandomNumber();
        num2 = this.getRandomNumber();
        num3 = this.getRandomNumber();
        operator = this.enabledOperators[Math.floor(Math.random() * this.enabledOperators.length)];
        operator1 = this.enabledOperators[Math.floor(Math.random() * this.enabledOperators.length)];
        result = eval(`${num1} ${operator1} ${num2} ${operator} ${num3}`);
      }

      const s1 = num1 < "0" ? "(" + num1 + ")" : num1;
      const s2 = num2 < "0" ? "(" + num2 + ")" : num2;
      const sr = result < "0" ? "(" + result + ")" : result;
      return `${s1} ${operator1} ${s2} = _____ ${operator} ${sr}`;
    },
    generateExpressions () {
      const expressions = [];
      for (let i = 0; i < 36; i++) {
        expressions.push(this.generateExpression());
      }
      this.mathExpressions = expressions;
    },
    printExpressions () {
      window.print();
    }
  }
};
</script>



<style scoped>
.expression-container {
  align-items: center; /* 水平居中 */
  display: flex;
  flex-wrap: wrap;
}

.expression {
  width: 30%; /* 每个表达式的宽度为25%，以确保一行显示四个 */
  box-sizing: border-box; /* 包含边框和内边距在内的总宽度不超过25% */
  padding: 10px;
  margin: 5px;
}
.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.input-section {
  /* 样式您的输入部分 */
}

.button-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.print-button {
  display: none; /* 初始时隐藏打印按钮 */
}

@media print {
  @page {
    size: auto; /* 默认页面大小 */
    margin: 0; /* 边距设置为0 */
  }

  /* 隐藏默认页眉和页脚信息 */
  thead {
    display: table-row-group;
  }
  tfoot {
    display: table-row-group;
  }
  tfoot:before {
    display: none;
  }
  tfoot:after {
    display: none;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  .input-section {
    display: none;
  }
  .button-section {
    display: none; /* 在打印时隐藏按钮部分 */
  }
  .print-button {
    display: block; /* 在打印时显示打印按钮 */
  }
}
</style>